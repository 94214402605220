import React, { useContext } from "react";
import { AppContext } from "../App";
import { Button, Tooltip } from "@mui/material";
import { ExitToApp, GroupAdd, Person, PersonAddAlt1 } from "@mui/icons-material";
import DialogModal from "./Dialog";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const { user, setDialog, setUser, setAlert } = useContext(AppContext);
  const router = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await axios({
        url: process.env.REACT_APP_API + "/champion/logout",
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
        },
      });
      setUser(res.data.data.user);
      localStorage.removeItem("tokenChampion");
      setAlert({ type: "success", message: res.data.message });
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", message: "Veuillez réessayer!" });
    }
  };
  return (
    <header id="navbar">
      <div id="logo">
        <img
          src="logo.png"
          alt="Teraka logo"
          style={{ width: 100 }}
          onClick={() => window.open("https://www.teraka.org", "_blank")}
        />
      </div>
      <div className="flex gap-3 items-center">
        <div className="menu-btn">
          <button
            className="nav-btn"
            style={{
              fontSize: "12px",
            }}
            onClick={() => window.open("https://programme.teraka.org", "_self")}
          >
            Programme TERAKA
          </button>
          <button
            className="nav-btn"
            style={{
              fontSize: "12px",
            }}
            onClick={() => window.open("https://rejoindre.teraka.org", "_self")}
          >
            Formation TERAKA
          </button>
        </div>
        {user ? (
          <>
            <Tooltip title="Hampiditra vondrona">
              <Button variant="outlined" onClick={()=> router("/")}>
                <GroupAdd />
              </Button>
            </Tooltip>
            <Tooltip title="Hampiditra olona">
              <Button variant="outlined" onClick={()=> router("/adduser")}>
                <PersonAddAlt1 />
              </Button>
            </Tooltip>
            <Tooltip title="Hivoaka">
              <Button variant="outlined" onClick={handleLogout}>
                <ExitToApp />
              </Button>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Champion">
            <Button
              variant="contained"
              onClick={() => setDialog(<DialogModal />)}
            >
              <Person />
            </Button>
          </Tooltip>
        )}
      </div>
    </header>
  );
}
