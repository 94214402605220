import React, { createContext, useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Ajout from "./pages/Ajout";
import { Alert, CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "./components/theme";
import Contact from "./pages/Contact";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Adduser from "./pages/Adduser";

export const AppContext = createContext(null);

function App() {
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let a = null;
    if (alert) {
      a = setTimeout(() => {
        setAlert(null);
      }, 3000);
    }
    return () => {
      if (a) {
        clearTimeout(a);
      }
    };
  }, [alert]);
  useEffect(() => {
    if (localStorage.getItem("tokenChampion")) {
      fetch(process.env.REACT_APP_API + "/champion/check", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
        },
      })
        .then((r) => r.json())
        .then((res) => {
          setUser(res.data.user);
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("tokenChampion");
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={50} />
        </div>
      ) : (
        <AppContext.Provider value={{ user, setUser, setAlert, setDialog }}>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route
                path="/"
                element={<section>{user ? <Ajout /> : <Contact />}</section>}
              />
              <Route
                path="/adduser"
                element={<section>{user ? <Adduser /> : <Contact />}</section>}
              />
            </Routes>
          </BrowserRouter>
          {dialog}
        </AppContext.Provider>
      )}
      {alert && (
        <div id="alert">
          <Alert severity={alert.type} variant="filled">
            <strong>{alert.message}</strong>
          </Alert>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
