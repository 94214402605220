export const lieu = [
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambatondrazaka I",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambatondrazaka II",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMBATONDRAZAKA", COMMUNE: "Didy" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambandrika",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambatosoratra",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambohiboromanga",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambodidava",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ambohitsilaozana",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Amparihitsokatra",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Ampitatsimo",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Andilanatoby",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Andromba",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Antanandava",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Antsangatsanga",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Feramanga Avaratra",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMBATONDRAZAKA", COMMUNE: "Ilafy" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Imerimandroso",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Manakambahiny Andrefana",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Manakambahiny Atsinanana",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Soalazaina",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMBATONDRAZAKA",
    COMMUNE: "Tanambao Besakay",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMBATONDRAZAKA", COMMUNE: "Bejofo" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Amparafaravola",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ambatomainty",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ambodimanga",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ambohidrano",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ambohijanahary",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Andilana Avaratra",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Andranobe",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Andrebakely I",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Andrebakely II",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMPARAFARAVOLA", COMMUNE: "Anororo" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMPARAFARAVOLA", COMMUNE: "Bedidy" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Morarano Kiraoma",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMPARAFARAVOLA", COMMUNE: "Tanambe" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Vohimenakely",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Vohitsara",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "AMPARAFARAVOLA", COMMUNE: "Beanana" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Amboavory",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ranomainty Tsarahonenana",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Andilamena" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Bemaitso" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Marovato" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Miarinarivo" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Ampondrabe" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Maitsokely" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANDILAMENA",
    COMMUNE: "Tanananifololahy",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANDILAMENA",
    COMMUNE: "Antanimenabaka",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "ANDILAMENA", COMMUNE: "Maroadabo" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Anosibe An'Ala",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Ambalaomby",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Ambatoharanana",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Ampandroantraka",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Ampasimaneva",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Tratramarina",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Tsaravinany",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Niarovana",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Antandrokomby",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Moramanga" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Ambatovola" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Amboasary Gara",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Ambohibary" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Ambohidronono",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Ampasimpotsy Gara",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Ampasipotsy Mandialaza",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Analasoa" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Andasibe" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Anosibe Ifody",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Antanandava" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Antaniditra" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Beforona" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Belavabary" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Fierenana" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Lakato" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Mandialaza" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Beparasy" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Morarano Gara",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "MORAMANGA",
    COMMUNE: "Sabotsy Anjiro",
  },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Vodiriana" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Bembary" },
  { REGION: "ALAOTRA MANGORO", DISTRICT: "MORAMANGA", COMMUNE: "Andaingo" },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Sahamamy",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ambohitrarivo",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ampasikely",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "AMPARAFARAVOLA",
    COMMUNE: "Ambohimandroso I",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Ambohimerana",
  },
  {
    REGION: "ALAOTRA MANGORO",
    DISTRICT: "ANOSIBE AN'ALA",
    COMMUNE: "Longozabe",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Alakamisy Ambohijato",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ambatofitorahana",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ambinanindrano",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ambohimitombo I",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ambohimitombo II",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ambohipirevoana",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Andina" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Antoetra" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Fahizay" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ilaka Afovoany",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Ivony" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Kianjandrakefina",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Mahazina" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Sahatsiho Ambohimanjaka",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Vohidahy" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Ihadilanana" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Tsarasaotra" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Ambositra I" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Ankazoambo" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Imerin'Imady" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ambalamanakana",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Ivato" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Ambatofinandrahana",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Ambatomifanongoa",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Ambondromisotra",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Amborompotsy",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Fenoarivo",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Itremo",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Mandrosonoro",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Mangataboahangy",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBATOFINANDRAHANA",
    COMMUNE: "Soavina",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Ambohimahazo" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Ankarinoro" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "FANDRIANA",
    COMMUNE: "Isandrandahy Ambony",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Mahazoarivo" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Sandrandahy" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Tsarazaza" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "FANDRIANA",
    COMMUNE: "Sahamadio Fisakana",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Fiadanana" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Fandriana" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Milamaina" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Anjoman'Ankoana",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "MANANDRIANA", COMMUNE: "Andakatany" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Ambatomarina",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "MANANDRIANA", COMMUNE: "Ilanjana" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Vinany Andakatanikely",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "MANANDRIANA", COMMUNE: "Soatanana" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Ambohimilanja",
  },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Ambohimahazo",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "MANANDRIANA", COMMUNE: "Ambohipo" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Anjoma Nandihizana",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "MANANDRIANA", COMMUNE: "Ambovombe" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "MANANDRIANA",
    COMMUNE: "Talata Vohimena",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Tatamalaza" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Betsimisotra" },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Soanakambana" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "FANDRIANA",
    COMMUNE: "Miarinavaratra",
  },
  { REGION: "AMORON'I MANIA", DISTRICT: "FANDRIANA", COMMUNE: "Imito" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Ambositra II" },
  { REGION: "AMORON'I MANIA", DISTRICT: "AMBOSITRA", COMMUNE: "Marosoa" },
  {
    REGION: "AMORON'I MANIA",
    DISTRICT: "AMBOSITRA",
    COMMUNE: "Ankazotsararavina",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "AMBOHIDRATRIMO",
    COMMUNE: "Ambohidratrimo",
  },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Ivato" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Talatamaty" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Ampangabe" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Anjanadoria" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Antsahafilo" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Fiadanana" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Iarinarivo" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Mahabo" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Mahereza" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Anosiala" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Mahitsy" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Antanetibe" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Ambato" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "AMBOHIDRATRIMO",
    COMMUNE: "Manjakavaradrano",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "AMBOHIDRATRIMO",
    COMMUNE: "Ambohimanjaka",
  },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Mananjara" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Ampanotokana" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Avaratsena" },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Antehiroka" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "AMBOHIDRATRIMO",
    COMMUNE: "Ambatolampy Tsimahafotsy",
  },
  { REGION: "ANALAMANGA", DISTRICT: "AMBOHIDRATRIMO", COMMUNE: "Merimandroso" },
  { REGION: "ANALAMANGA", DISTRICT: "ANDRAMASINA", COMMUNE: "Ambohimiadana" },
  { REGION: "ANALAMANGA", DISTRICT: "ANDRAMASINA", COMMUNE: "Andramasina" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Alarobia Vatosola",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANDRAMASINA", COMMUNE: "Andohariana" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Anjoma Faliarivo",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Anosibe Trimoloharano",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Fitsinjovana Bakaro",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Morarano Soafiraisana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Sabotsy Ambohitromby",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANDRAMASINA", COMMUNE: "Tankafatra" },
  { REGION: "ANALAMANGA", DISTRICT: "ANDRAMASINA", COMMUNE: "Antotohazo" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Alatsinainy Bakaro",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANDRAMASINA", COMMUNE: "Mandrosoa" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANDRAMASINA",
    COMMUNE: "Sabotsy Manjakavahoaka",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Anjozorobe" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Alakamisy" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Ambatomanoina" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Amboasary" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANJOZOROBE",
    COMMUNE: "Ambohibary Vohilena",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANJOZOROBE",
    COMMUNE: "Ambohimarina Marovazaha",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Ambohimirary" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Ambongamarina" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANJOZOROBE",
    COMMUNE: "Amparatanjona Ambony",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Analaroa" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Andranomisa" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Androvakely" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Antanetibe" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Beronono" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Betatao" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Mangamila" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Marotsipoy" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Tsarasaotra" },
  { REGION: "ANALAMANGA", DISTRICT: "ANJOZOROBE", COMMUNE: "Belanitra" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Ambohitromby" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Ambolotarakely" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Andranomiely" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Antakavana" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Antotohazo" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Fiadanana" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Kiangara" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Mahavelona" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Mangasoavina" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Marondry" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Tsaramandroso" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Miantso" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Fihaonana" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ampitatafika",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Andranonahoatra",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Bemasoandro",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Itaosy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Alatsinainy Ambazaha",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ambohidrapeto",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ampanefy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Androhibe",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ankadimanga",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Fiombonana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Soavina",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Anosizato Andrefana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Soalandy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Antanetikely",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ambalavao",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ampahitrosy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Bongatsara",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ambohijanaka",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Tsiafahy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ambatofahavalo",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ambohidrabiby",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Vilihazo",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Anosy Avaratra",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Manandriana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Sabotsy Namehana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ankadikely Ilafy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Fieferana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ambohimalaza Miray",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ambohimangakely",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ambohimanambola",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Alasora",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Anjeva Gara",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Masindray",
  },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Mantasoa" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ambatolaona" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ambatomanga" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ambatomena" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Anjepy" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ankazondandy" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Merikanjaka" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ranovao" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Sadabe" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Soavinandriana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Ambohitrandriamanitra",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Miadanandriana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Alarobia Ambatomanga",
  },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ambanitsena" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ampaneva" },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ambohibary" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Manjakandriana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Antsahalalina",
  },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Ambohitrony" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Ambohitseheno",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Ambohitrolomahitsy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Ambohibao Atsimo",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "AMBOHIDRATRIMO",
    COMMUNE: "Ambohitrimanjaka",
  },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Ankazobe" },
  { REGION: "ANALAMANGA", DISTRICT: "ANKAZOBE", COMMUNE: "Talatan'Angavo" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Alakamisy Fenoarivo",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Fenoarivo",
  },
  { REGION: "ANALAMANGA", DISTRICT: "MANJAKANDRIANA", COMMUNE: "Sambaina" },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Nandihizana Carion",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Talata Volonondry",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "AMBOHIDRATRIMO",
    COMMUNE: "Ambohipihaonana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ambavahaditokana",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Tanjombato",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Andoharanofotsy",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO RENIVOHITRA",
    COMMUNE: "Antananarivo",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ambohimanga Rova",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "MANJAKANDRIANA",
    COMMUNE: "Anjoma Betoho",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO ATSIMONDRANO",
    COMMUNE: "Ankaraobato",
  },
  {
    REGION: "ANALAMANGA",
    DISTRICT: "ANTANANARIVO AVARADRANO",
    COMMUNE: "Ankadinandriana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Ambanjan'ny Sahalava",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Ambatoaranana Tsaratampona",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Ambodimanga II",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Ampasimbe Manantsantrana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Ampasina Maningory",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Antsiatsiaka",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Vohilengo",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Vohipeno",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Miaromivalana I",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Saranambana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Fenoarivo Atsinanana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Mahanoro",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Ambodiampana" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Imorona" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Saromaona" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Ambodivoanio" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Analampotsy" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Antanambaobe" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Sandrakatsy" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Manambolosy" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Maroantsetra" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Ambanizana" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Ambinanitelo" },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "MAROANTSETRA",
    COMMUNE: "Ambodimanga Rantabe",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Androndrona" },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "MAROANTSETRA",
    COMMUNE: "Anjanazana Ankadimbazaha",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Ankofa" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Ankofabe" },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "MAROANTSETRA",
    COMMUNE: "Antsirabe Sahatany",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Manambolo" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Mariharano" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Morafeno" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Rantabe" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Sahasindro" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Ivoloina" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Anjahana" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Mahalevona" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Andranofotsy" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Antakotako" },
  { REGION: "ANALANJIROFO", DISTRICT: "MAROANTSETRA", COMMUNE: "Anandrivola" },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Ambinanisakana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Andapafito",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Antenina",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Fotsialanana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Soanierana Ivongo",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Antanifotsy",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Ambodiampana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SAINTE MARIE",
    COMMUNE: "Ambodifotatra",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Vavatenina" },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "VAVATENINA",
    COMMUNE: "Ambodimangavalo",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Ambohibe" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Anjahambe" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Miarinarivo" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Sahatavy" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Andasibe" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Ambatoharanana" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Tanamarina" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Mananara" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Antananivo" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Antanambe" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Mahanoro" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Vanono" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Andasibe" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Tanibe" },
  { REGION: "ANALANJIROFO", DISTRICT: "MANANARA", COMMUNE: "Ambatoharanana" },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Ampasimazava" },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Betampona",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Ambahoabe",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "SOANIERANA IVONGO",
    COMMUNE: "Manompana",
  },
  {
    REGION: "ANALANJIROFO",
    DISTRICT: "FENOARIVO ATSINANANA",
    COMMUNE: "Mahambo",
  },
  { REGION: "ANALANJIROFO", DISTRICT: "VAVATENINA", COMMUNE: "Maromitety" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Beloha" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Ambatotsivala" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Beabobo" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Ikopoky" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Mahaenene" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Marolinta" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Tranoroa" },
  { REGION: "ANDROY", DISTRICT: "BELOHA", COMMUNE: "Tranovaho" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Tsiombe" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Anjampaly" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Ankilivalo" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Antaritarika" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Behazomanga" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Faux Cap" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Imongy" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Marovato" },
  { REGION: "ANDROY", DISTRICT: "TSIOMBE", COMMUNE: "Nikoly" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ambovombe" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ambanisarike" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ambazoa" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ambohimalaza" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ambonaivo" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ambondro" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Ampamata" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Analamary" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Andalatanosy" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Andoharano" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Andragnanivo" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Anjeke Ankilikira" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Antanimora Atsimo" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Erada Tsimanato" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Imanombo" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Jafaro" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Maroalomainty" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Maroalopoty" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Marovato Befeno" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Sihanamaro" },
  { REGION: "ANDROY", DISTRICT: "AMBOVOMBE", COMMUNE: "Tsimananada" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Bekily Morafeno" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Ambahita" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Ambatosola" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Anivorano Mitsinjo" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Anja Avaratra" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Ankaranabo Avaratra" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Bekitro" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Belindo Mahasoa" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Beraketa" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Besakoa" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Beteza" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Bevitiky" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Manakompy" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Besaroa Maroviro" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Mikaikarivo Ambatomainty" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Tanandava" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Tsikolaky" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Vohimanga" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Tanambao Tsirandrany" },
  { REGION: "ANDROY", DISTRICT: "BEKILY", COMMUNE: "Antskoamaro" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Tolanaro" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ampasy Nahampoana" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Mandromodromotra" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Soanierana" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ambatoabo" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ampasimena" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Analamary" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Analapatsa I" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Andranobory" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ankaramena" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ankarera" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ankilivalo" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Bevoay" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Emanobo" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Enakara Ambony" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Enaniliha" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Fenoevo Efita" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Iaboakoho" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ifarantsa" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Isaka Ivondro" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Mahatalaky" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Manambaro" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Manantenina" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Mandiso" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ranomafana Atsimo" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Ranopiso" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Sarasambo" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Soavary" },
  { REGION: "ANOSY", DISTRICT: "TAOLANARO", COMMUNE: "Tanandava" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Betroka" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Ambalasoa" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Ambatomivary" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Analamary" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Andriandampy" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Beapombo I" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Beapombo II" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Bekorobo" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Benato Toby" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Iaborotra" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Ianabinda" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Ianakafy" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Isoanala" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Ivahona" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Jangany" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Kelivaho" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Mahabo" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Mahasoa Atsinanana" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Nanarena Besakoa" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Nanirona" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Tsaraitso" },
  { REGION: "ANOSY", DISTRICT: "BETROKA", COMMUNE: "Sakamahily" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Amboasary" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Behara" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Berano Ville" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Ebelo" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Eloty" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Esira" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Ifotaka" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Mahabo" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Mahaly" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Manevy" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Maromby" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Marotsiraka" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Ranobe" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Tanandava Atsimo" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Tranomaro" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Tsivory" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Tomboarivo" },
  { REGION: "ANOSY", DISTRICT: "AMBOASARY", COMMUNE: "Sampona" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA I", COMMUNE: "Toliara I" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Beroroha" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Behisatra" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Fanjakana" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Mandronarivo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Marerano" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Sakena" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Tanamary" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Tanandava" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BEROROHA", COMMUNE: "Bemavo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "MOROMBE", COMMUNE: "Morombe" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "MOROMBE", COMMUNE: "Ambahikily" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "MOROMBE",
    COMMUNE: "Ankatsakantsa Atsimo",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "MOROMBE", COMMUNE: "Antanimieva" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "MOROMBE",
    COMMUNE: "Antongo Vaovao",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "MOROMBE", COMMUNE: "Basibasy" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "MOROMBE", COMMUNE: "Befandefa" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "MOROMBE",
    COMMUNE: "Befandriana Atsimo",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "MOROMBE", COMMUNE: "Tanandava" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "ANKAZOABO",
    COMMUNE: "Ankazoabo Atsimo",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "ANKAZOABO", COMMUNE: "Ambatolahy" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "ANKAZOABO",
    COMMUNE: "Andranomafana",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "ANKAZOABO", COMMUNE: "Ankeriky" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "ANKAZOABO",
    COMMUNE: "Ankilivalokely",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "ANKAZOABO", COMMUNE: "Berenty" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "ANKAZOABO", COMMUNE: "Fotivolo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "ANKAZOABO", COMMUNE: "Ilemby" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "ANKAZOABO", COMMUNE: "Tandrano" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "BETIOKY",
    COMMUNE: "Ankazomanga Andrefana",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "BETIOKY",
    COMMUNE: "Maroarivo Ankazomanga",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Marosavoa" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Masiaboay" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Soaserana" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Ampanihy" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Amboropotsy" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Anavoha" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Androipano" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Androka" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Ankiliabo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Ankilimivory" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Ankilizato" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Antaly" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Beahitse" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "AMPANIHY",
    COMMUNE: "Belafika Ambony",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Fotadrevo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Gogogogo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Itampolo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Maniry" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Vohitany" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Sakaraha" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Ambinany" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Amboronabo" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "SAKARAHA",
    COMMUNE: "Vineta Andamasiny",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Andranolava" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Beraketa" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Mahaboboka" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "SAKARAHA",
    COMMUNE: "Miary Lamatihy",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Miary Taheza" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Mihavatsy" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "SAKARAHA", COMMUNE: "Mikoboka" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "SAKARAHA",
    COMMUNE: "Mitsinjo Kiliarivo",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Ankiloaka I",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Mitsinjo Betanimena",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Ambohimahavelona",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Ambolofotsy",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Analamisampy",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Andranohinaly",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Andranovory",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Ankilimalinike",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Antanimena" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Beheloka" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Behompy" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Belalanda" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Betsinjaka" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Efoetse" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Manombo Atsimo",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Manorofify" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Marofoty" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Maromiandra",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Miary" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Milenake" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Saint Augustin",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Soahazo" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "TOLIARA II",
    COMMUNE: "Soalara Atsimo",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Tsianisiha" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Tsifota" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "TOLIARA II", COMMUNE: "Anakao" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BENENITRA", COMMUNE: "Ambalavato" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BENENITRA", COMMUNE: "Benonoky" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Ejeda" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "BETIOKY",
    COMMUNE: "Betioky Atsimo",
  },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "BETIOKY",
    COMMUNE: "Ambatry Mitsinjo",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Ankazombalala" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Ankilivalo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Antohabato" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Antsavoa" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Beantake" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Belamoty" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Beora" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Besely" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Bezaha" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Fenoandala" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Lazarivo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Manalobe" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Sakamasay" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Salobe" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Soamanonga" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Tameantsoa" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Tongobory" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Vatolatsaka" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "BETIOKY",
    COMMUNE: "Andranomangatsiaka",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Montifeno" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BENENITRA", COMMUNE: "Benenitra" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BENENITRA", COMMUNE: "Ankilimary" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BENENITRA", COMMUNE: "Ianapera" },
  {
    REGION: "ATSIMO ANDREFANA",
    DISTRICT: "BETIOKY",
    COMMUNE: "Tanambao Ambony",
  },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Vohimary" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BETIOKY", COMMUNE: "Savazy" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Beara" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "AMPANIHY", COMMUNE: "Beroy Atsimo" },
  { REGION: "ATSIMO ANDREFANA", DISTRICT: "BENENITRA", COMMUNE: "Ehara" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Farafangana",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ambalavato Antevato",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ambalavato Avaratra",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ambohigogo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ambohimandroso",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Amporoforo",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Beretra" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Etrotroka",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Evato" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Ihorombe" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ivandrika",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Mahabo Mananivo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Maheriraty",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Marovandrika",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Sahamadio",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Vohilengo",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Vohimasy" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Vohilavan'i Manampatra",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Anosivelo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "BEFOTAKA",
    COMMUNE: "Befotaka Atsimo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "BEFOTAKA",
    COMMUNE: "Antaninarenina",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "BEFOTAKA",
    COMMUNE: "Antokoboritelo",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "BEFOTAKA", COMMUNE: "Beharena" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "BEFOTAKA",
    COMMUNE: "Bekofafa Atsimo",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "BEFOTAKA", COMMUNE: "Inosy" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "BEFOTAKA",
    COMMUNE: "Marovitsika Atsimo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "BEFOTAKA",
    COMMUNE: "Ranotsara Atsimo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "MIDONGY",
    COMMUNE: "Nosifeno ( ex Midongy)",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "MIDONGY",
    COMMUNE: "Andranolalina",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "MIDONGY", COMMUNE: "Ankazovelo" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "MIDONGY", COMMUNE: "Bevaho" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "MIDONGY", COMMUNE: "Ivondro" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "MIDONGY", COMMUNE: "Soakibany" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "MIDONGY", COMMUNE: "Zaraha" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Vangaindrano",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Ambatolava",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Bekaraoka",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Bevata" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Fenoambany",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Iara" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Karimbary",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Mahabe" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Manambondro",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Marokibo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Sandravinany",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Tsiately",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Fonilaza",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Vohitrambo",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Lohafary",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Anilobe" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Bema" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Ampataka",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Ampasimalemy",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Tsianofana",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Vohimalaza",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Vatanato",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Isahara" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Ambongo" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Ranomena",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Matanga" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Vondrozo" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Ambodirano" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Anandravy" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Andakana" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Antokonala" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Iamonta" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Ivato" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Karianga" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Mahavelo" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Manambidala" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Manato" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Moroteza" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Vohimary" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Vohiboreka" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Mahatsinjo" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Andoharano" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Anosy Tsararafa",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ambalatany",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Namohora Iaborano",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Ambohimana" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VONDROZO", COMMUNE: "Mahazoarivo" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Tangainony",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Mahavelo" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Mahafasa Afovoany",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Vohitromby",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Manambotra Atsimo",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "BEFOTAKA", COMMUNE: "Antondabe" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Amparihy Atsinanana",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Soamanova",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Iabohazo" },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "FARAFANGANA", COMMUNE: "Tovona" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Antseranambe",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Ankarana Miraihina",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Efatsy Anandroza",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "FARAFANGANA",
    COMMUNE: "Fenoarivo",
  },
  { REGION: "ATSIMO ATSINANANA", DISTRICT: "VANGAINDRANO", COMMUNE: "Lopary" },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Vohipaho",
  },
  {
    REGION: "ATSIMO ATSINANANA",
    DISTRICT: "VANGAINDRANO",
    COMMUNE: "Masianaka Nosibe",
  },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Ambohimilanja" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Andonabe Atsimo" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Anosiarivo" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Maromitety I" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "MAROLAMBO",
    COMMUNE: "Tanambao Rabemanana",
  },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Amboasary" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Lohavanana" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Sahakevo" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Ambodinonoka" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "AmbalapaisoII" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Ambalarondra" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Ambinaninony" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Ambohimanana" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Ampasimbe" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Andekaleka" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Andevoranto" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Anjahamana" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Antsampanana" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Fanasana" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Fetraomby" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Lohariandava" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Mahatsara" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Maroseranana" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "BRICKAVILLE",
    COMMUNE: "Ranomafana Atsinanana",
  },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Razanaka" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Vohitranivona" },
  { REGION: "ATSINANANA", DISTRICT: "BRICKAVILLE", COMMUNE: "Brickaville" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "BRICKAVILLE",
    COMMUNE: "Anivorano Atsinanana",
  },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Mahanoro" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Ambinanidilana" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Ambinanindrano" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Ambodibonara" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Ambodiharina" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Ankazotsifantatra" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Befotaka" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Betsizaraina" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Manjakandriana" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Masomeloka" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Tsaravinany" },
  { REGION: "ATSINANANA", DISTRICT: "MAHANORO", COMMUNE: "Andranambomaro" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "ANTANAMBAO MANAMPOTSY",
    COMMUNE: "Antanandehibe",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "ANTANAMBAO MANAMPOTSY",
    COMMUNE: "Manakana",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "ANTANAMBAO MANAMPOTSY",
    COMMUNE: "Manaratsandry",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "ANTANAMBAO MANAMPOTSY",
    COMMUNE: "Mahela",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "ANTANAMBAO MANAMPOTSY",
    COMMUNE: "Saivaza",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "ANTANAMBAO MANAMPOTSY",
    COMMUNE: "Antanambao Manampotsy",
  },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA I", COMMUNE: "Toamasina" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Ambodiriana" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "TOAMASINA II",
    COMMUNE: "Amboditandroho Mahatsara",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "TOAMASINA II",
    COMMUNE: "Ampasimadinika Manambolo",
  },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Amporoforo" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Antenina I" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Antetezambaro" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Mangabe" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Ampisokina" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Andondabe" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Mahavelona" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Fanandrana" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Andranobolaha" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ambodivoananto" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "VATOMANDRY",
    COMMUNE: "Antanambao Mahatsara",
  },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Iamborano" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ifasina I" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ifasina II" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Niherenana" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Tsivangiana" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "VATOMANDRY",
    COMMUNE: "Ambodivononoka Ranganana",
  },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ifasina III" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Amboditavolo" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Sahamatevina" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Vatomandry" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Maintinandry" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ilaka" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "VATOMANDRY",
    COMMUNE: "Niarovana Caroline",
  },
  {
    REGION: "ATSINANANA",
    DISTRICT: "TOAMASINA II",
    COMMUNE: "Ampasimbe Onibe",
  },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Sahambala" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Ambodilazana" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Satrandroy" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Fito Sahaviavy" },
  { REGION: "ATSINANANA", DISTRICT: "TOAMASINA II", COMMUNE: "Toamasina II" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ambalabe" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ambalavolo" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Tsarasambo" },
  {
    REGION: "ATSINANANA",
    DISTRICT: "VATOMANDRY",
    COMMUNE: "Tanambao Vahatrakaka",
  },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ampasimadinika" },
  { REGION: "ATSINANANA", DISTRICT: "VATOMANDRY", COMMUNE: "Ampasimazava" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Ambodivoahangy" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Ambatofisaka II" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Androrangavola" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Marolambo" },
  { REGION: "ATSINANANA", DISTRICT: "MAROLAMBO", COMMUNE: "Betampona" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Andasibe" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Mahatsinjo Atsimo" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Maevatanana I" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Andriba" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Antanimbary" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Antsiafabositra" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Beratsimanina" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Berivotra" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Mahatsinjo" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Mangabe" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Marokoro" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Morafeno" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Tsararano" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Tsaratanana" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Ambakireny" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Ampandrana" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Andriamena" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Bekapaika" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Betrandraka" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Brieville" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Isinko" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Keliloha" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Manakana" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Sakoamadinika" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Sarobaratra" },
  { REGION: "BETSIBOKA", DISTRICT: "TSARATANANA", COMMUNE: "Tsararova" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Ambalanjanakomby" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Madiromirafy" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Ambalajia" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Maevatanana II" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Bemokotra" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Mahazoma" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Antanimbaribe" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Kandreho" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Ambaliha" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Behazomaty" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Maria" },
  { REGION: "BETSIBOKA", DISTRICT: "KANDREHO", COMMUNE: "Betaimboay" },
  { REGION: "BETSIBOKA", DISTRICT: "MAEVATANANA", COMMUNE: "Beanana" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAI", COMMUNE: "Mahajanga I" },
  { REGION: "BOENY", DISTRICT: "SOALALA", COMMUNE: "Soalala" },
  { REGION: "BOENY", DISTRICT: "SOALALA", COMMUNE: "Ambohipaky" },
  { REGION: "BOENY", DISTRICT: "SOALALA", COMMUNE: "Andranomavo" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Antsakoamileka" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Ambato Ambarimay" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Andranofasika" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Ambesisika" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Andranomamy" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Anjiajia" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Ankijabe" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Ankirihitra" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Madirovalo" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Manerinerina" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Sitampiky" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Tsaramandroso" },
  { REGION: "BOENY", DISTRICT: "AMBATOBOENY", COMMUNE: "Ambondromamy" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Marovoay" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Marovoay II" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Ambolomoty" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Ankaboka" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Ankaraobato" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Ankazomborona" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Anosinalainolona" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Antanambao Andranolava" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Antanimasaka" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Bemaharivo" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Manaratsandry" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Marosakoa" },
  { REGION: "BOENY", DISTRICT: "MAROVOAY", COMMUNE: "Tsararano" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Mitsinjo" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Ambarimaninga" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Antseza" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Antongomena Bevary" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Bekipay" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Katsepy" },
  { REGION: "BOENY", DISTRICT: "MITSINJO", COMMUNE: "Matsakabanja" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Ambalabe" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Ambalakida" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Andranoboka" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Bekobay Afovoany" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Belobaka" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Betsako" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Bemaharivo" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Boanamary" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Mahajamba Usine" },
  { REGION: "BOENY", DISTRICT: "MAHAJANGAII", COMMUNE: "Mariarano" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Fenoarivobe" },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "FENOARIVOBE",
    COMMUNE: "Ambatomainty Atsimo",
  },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Ambohitromby" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Firavahana" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Kiranomena" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Mangatany" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Tsinjoarivo 22" },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "TSIROANOMANDIDY",
    COMMUNE: "Tsiroanomandidy",
  },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "TSIROANOMANDIDY",
    COMMUNE: "Ankadinondry Sakay",
  },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Ambatolampy" },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "TSIROANOMANDIDY",
    COMMUNE: "Ankerana Avaratra",
  },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Antsahalava" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Belobaka" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Bemahatazana" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Fierenana" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Maroharona" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Maritampona" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Miandrarivo" },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "TSIROANOMANDIDY",
    COMMUNE: "Tsiroanomandidy Fihaonana",
  },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Ambararatabe" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Mahasolo" },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "TSIROANOMANDIDY",
    COMMUNE: "Ambavahady Anosy",
  },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Ambalanirana" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Mahajeby" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Bevato" },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Soanierana" },
  { REGION: "BONGOLAVA", DISTRICT: "FENOARIVOBE", COMMUNE: "Andriampotsy" },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "TSIROANOMANDIDY",
    COMMUNE: "Tsinjoarivo Imanga",
  },
  {
    REGION: "BONGOLAVA",
    DISTRICT: "FENOARIVOBE",
    COMMUNE: "Morarano Marotampona",
  },
  { REGION: "BONGOLAVA", DISTRICT: "TSIROANOMANDIDY", COMMUNE: "Fiadanantsoa" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambanja" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambalahonko" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambaliha" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambodimanga Ramena" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambohimarina" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambohimena" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ambohitrandriana" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ankatafa" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Anorotsangana" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Antafiambotry" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Antranokarany" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Antsahabe Afovoany" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Antsakoamanondro" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Antsatsaka" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Antsirabe" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Bemanevika" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Bemanevika Andrefana" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Benavony" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Djangoa" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Maevatanana" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Maherivaratra" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Marotolana" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Marovato" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Ambilobe" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Ambarakaraka" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Ambodibonara" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Anaborano Ifasy" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Anjiabe Ambony" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Antsohimbondrona" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Beramanja" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Betsiaka" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Manambato" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Mantaly" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Antanambe" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Ambatoben' Anjavy" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Ambakirano" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Ampondralava" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Tanambao Marivorahona" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Sirama" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA I", COMMUNE: "Antsiranana I" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Andranovondronina" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Mangaoka" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Antsahampano" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Ramena" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Mahavanona" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Mahalina" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Andranofanjava" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Anketrakabe" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Ankarongana" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Mosorolava" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Bobasakoa" },
  {
    REGION: "DIANA",
    DISTRICT: "ANTSIRANANA II",
    COMMUNE: "Anivorano Avaratra",
  },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Ambondrona" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Bobakilandy" },
  {
    REGION: "DIANA",
    DISTRICT: "ANTSIRANANA II",
    COMMUNE: "Antanamitarana Atsimo",
  },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Sakaramy" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Joffre Ville" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Sadjoavato" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Andrafiabe" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Ambolombozobe" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Antsalaka" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Antsakoabe" },
  { REGION: "DIANA", DISTRICT: "ANTSIRANANA II", COMMUNE: "Antsoha" },
  { REGION: "DIANA", DISTRICT: "AMBANJA", COMMUNE: "Ankingameloka" },
  { REGION: "DIANA", DISTRICT: "NOSY BE", COMMUNE: "Nosy Be" },
  { REGION: "DIANA", DISTRICT: "AMBILOBE", COMMUNE: "Antsaravibe" },
  { REGION: "IHOROMBE", DISTRICT: "IAKORA", COMMUNE: "Iakora" },
  { REGION: "IHOROMBE", DISTRICT: "IAKORA", COMMUNE: "Andranombao" },
  { REGION: "IHOROMBE", DISTRICT: "IAKORA", COMMUNE: "Begogo" },
  { REGION: "IHOROMBE", DISTRICT: "IAKORA", COMMUNE: "Ranotsara Avaratra" },
  { REGION: "IHOROMBE", DISTRICT: "IAKORA", COMMUNE: "Volambita" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Ihosy" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Analavoka" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Andiolava" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Ankily" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Irina" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Mahasoa" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Menamaty Iloto" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Sahambano" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Sakalalina" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Satrokala" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Tolohomiady" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Andohan' Ilakaka" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Ranohira" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Ilakaka" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Antsoha" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Zazafotsy" },
  { REGION: "IHOROMBE", DISTRICT: "IVOHIBE", COMMUNE: "Ivohibe" },
  { REGION: "IHOROMBE", DISTRICT: "IVOHIBE", COMMUNE: "Antambohobe" },
  { REGION: "IHOROMBE", DISTRICT: "IVOHIBE", COMMUNE: "Antaramena" },
  { REGION: "IHOROMBE", DISTRICT: "IVOHIBE", COMMUNE: "Ivongo" },
  { REGION: "IHOROMBE", DISTRICT: "IVOHIBE", COMMUNE: "Maropaika" },
  { REGION: "IHOROMBE", DISTRICT: "IVOHIBE", COMMUNE: "Kotipa" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Ambia" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Analaliry" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Soamatasy" },
  { REGION: "IHOROMBE", DISTRICT: "IHOSY", COMMUNE: "Ambatolahy" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Arivonimamo I" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Imerintsiatosika" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Alakamisikely" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Ambatomanga" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Ambatomirahavavy" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Amboanana" },
  {
    REGION: "ITASY",
    DISTRICT: "ARIVONIMAMO",
    COMMUNE: "Ambohimandry Atsinanana",
  },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Ambohimasina" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Ambohitrambo" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Ampahimanga" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Andranomiely" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Antambolo" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Antenimbe" },
  {
    REGION: "ITASY",
    DISTRICT: "ARIVONIMAMO",
    COMMUNE: "Mahatsinjo Atsinanana",
  },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Manalalondo" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Marofangady" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Miantsoarivo" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Morafeno" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Morarano" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Talata Tsimadilo" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Miandrandra" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Ambohipandrano" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Miarinarivo" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Andolofotsy" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Antoby Atsinanana" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Manazary" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Mandiavato" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Miarinarivo II" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Zoma Bealoka" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Alatsinainikely" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Analavory" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Anosibe Ifanja" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Sarobaratra" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Ambatomanjaka" },
  {
    REGION: "ITASY",
    DISTRICT: "SOAVINANDRIANA",
    COMMUNE: "Amberomanga Atsinanana",
  },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Amparaky" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Ankaranana" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Mananasy" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Tamponala" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Mahavelona" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Ankisabe" },
  {
    REGION: "ITASY",
    DISTRICT: "SOAVINANDRIANA",
    COMMUNE: "Ambatoasana Afovoany",
  },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Masindray" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Amparibohitra" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Soavimbazaha" },
  { REGION: "ITASY", DISTRICT: "MIARINARIVO", COMMUNE: "Soamahamanina" },
  { REGION: "ITASY", DISTRICT: "ARIVONIMAMO", COMMUNE: "Arivonimamo II" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Ampefy" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Ampary" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Soavinandriana" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Antanetibe" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Ambohidanerana" },
  { REGION: "ITASY", DISTRICT: "SOAVINANDRIANA", COMMUNE: "Talata Dondona" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Ambalavao" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBALAVAO",
    COMMUNE: "Ambohimahamasina",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Anjoma" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Ankaramena" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBALAVAO",
    COMMUNE: "Ambinanindovoka",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBALAVAO",
    COMMUNE: "Ambinaniroa Andonaka",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBALAVAO",
    COMMUNE: "Ambohimandroso",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Andrainjato" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Besoa" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Fenoarivo" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBALAVAO",
    COMMUNE: "Iarintsena Firaisantsoa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBALAVAO",
    COMMUNE: "Kirano Firaisantsoa",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Mahazony" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Manamisoa" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Miarinarivo" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Vohitsaoka" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Volamena" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ampitana",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ankafina",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBOHIMAHASOA", COMMUNE: "Befeta" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Camp Robin",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBOHIMAHASOA", COMMUNE: "Isaka" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBOHIMAHASOA", COMMUNE: "Kalalao" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Manandroy",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Morafeno",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Sahatona",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Vohitrarivo",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "FIANARANTSOA I",
    COMMUNE: "Fianarantsoa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "IKALAMAVONY",
    COMMUNE: "Tanamarina Sakay",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "IKALAMAVONY", COMMUNE: "Mangidy" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "IKALAMAVONY",
    COMMUNE: "Ambatomainty",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "IKALAMAVONY", COMMUNE: "Solila" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "IKALAMAVONY",
    COMMUNE: "Tanamarina Bekisopa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "IKALAMAVONY",
    COMMUNE: "Ikalamavony",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "IKALAMAVONY", COMMUNE: "Fitampito" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Fanjakana" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "IKALAMAVONY",
    COMMUNE: "Tsitondroina",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Isorana" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "ISANDRA",
    COMMUNE: "Ambalamidera II",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "ISANDRA",
    COMMUNE: "Andoharanomaitso",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "ISANDRA",
    COMMUNE: "Ankarinarivo Manirisoa",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Nasandratrony" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Soatanana" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Mahazoarivo" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Ambondrona" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Alakamisy Ambohimaha",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Alatsinainy Ialamarina",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Ambalakely" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Ambalamahasoa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Andrainjato Afovoany",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Andrainjato Atsinanana",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Fandrandava" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Mahatsinjony",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Sahafata" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "ISANDRA",
    COMMUNE: "Iavinomby Vohibola",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Vinaninoro Andrefana",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Ivoamba" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Ialananindro",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Taindambo" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Sahambavy" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "LALANGINA", COMMUNE: "Androy" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Mahasoabe" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Talata Ampano",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Andranovorivato",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Ankaromalaza Mifanasoa",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Ihazoara" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Lamosina" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Maneva" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Soaindrana" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Vohimarina" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Vohitrafeno" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Vohibato Andrefana",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ambohinamboarina",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Fiadanana",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Vohiposa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ambatosoa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ambalakindresy",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ambohimahasoa",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "AMBOHIMAHASOA",
    COMMUNE: "Ankerana",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBOHIMAHASOA", COMMUNE: "Sahave" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Andreamalama" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "ISANDRA", COMMUNE: "Anjoma Itsara" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Anjanomanona Tsimiavaka",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Alakamisy Itenina",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "LALANGINA",
    COMMUNE: "Ampatsy Ampangabe",
  },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Andranomiditra",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "VOHIBATO", COMMUNE: "Mahaditra" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "VOHIBATO",
    COMMUNE: "Vinanitelo Andrefana",
  },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Sendrisoa" },
  { REGION: "MATSIATRA AMBONY", DISTRICT: "AMBALAVAO", COMMUNE: "Namoly" },
  {
    REGION: "MATSIATRA AMBONY",
    DISTRICT: "ISANDRA",
    COMMUNE: "Ambalamidera Ambohimanana",
  },
  { REGION: "MELAKY", DISTRICT: "AMBATOMAINTY", COMMUNE: "Makaraingo" },
  { REGION: "MELAKY", DISTRICT: "AMBATOMAINTY", COMMUNE: "Marotsialeha" },
  { REGION: "MELAKY", DISTRICT: "AMBATOMAINTY", COMMUNE: "Bemarivo" },
  { REGION: "MELAKY", DISTRICT: "ANTSALOVA", COMMUNE: "Bemaraha Atsinanana" },
  { REGION: "MELAKY", DISTRICT: "ANTSALOVA", COMMUNE: "Masoarivo" },
  { REGION: "MELAKY", DISTRICT: "ANTSALOVA", COMMUNE: "Soahany" },
  { REGION: "MELAKY", DISTRICT: "ANTSALOVA", COMMUNE: "Trangahy" },
  { REGION: "MELAKY", DISTRICT: "ANTSALOVA", COMMUNE: "Bekopaka" },
  { REGION: "MELAKY", DISTRICT: "ANTSALOVA", COMMUNE: "Antsalova" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Besalampy" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Ampako Ambalarano" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Ankasakasa Tsibiray" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Antsirasira" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Bekodoka" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Mahabe" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Soanenga" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Ambolodia Atsimo" },
  { REGION: "MELAKY", DISTRICT: "BESALAMPY", COMMUNE: "Marovoay Atsimo" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Maintirano" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Andabotoka" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Andranovao" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Andrea" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Ankisatra" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Antsondrondava" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Betanantanana" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Tambohorano" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Bebaboka Atsimo" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Berevo Ranobe" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Bemokotra Atsimo" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Veromanga" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Maromavo" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Antsaidoha Bebao" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Marohazo" },
  { REGION: "MELAKY", DISTRICT: "MORAFENOBE", COMMUNE: "Morafenobe" },
  { REGION: "MELAKY", DISTRICT: "MORAFENOBE", COMMUNE: "Beravina" },
  { REGION: "MELAKY", DISTRICT: "MORAFENOBE", COMMUNE: "Andramy" },
  { REGION: "MELAKY", DISTRICT: "MORAFENOBE", COMMUNE: "Antranokoaky" },
  { REGION: "MELAKY", DISTRICT: "AMBATOMAINTY", COMMUNE: "Ambatomainty" },
  { REGION: "MELAKY", DISTRICT: "AMBATOMAINTY", COMMUNE: "Sarodrano" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Mafajijo" },
  { REGION: "MELAKY", DISTRICT: "MAINTIRANO", COMMUNE: "Belitsaka" },
  {
    REGION: "MENABE",
    DISTRICT: "BELO SUR TSIRIBIHINA",
    COMMUNE: "Amboalimena",
  },
  {
    REGION: "MENABE",
    DISTRICT: "BELO SUR TSIRIBIHINA",
    COMMUNE: "Andimaky Manambolo",
  },
  {
    REGION: "MENABE",
    DISTRICT: "BELO SUR TSIRIBIHINA",
    COMMUNE: "Beroboka Avaratra",
  },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Delta" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Masoarivo" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Tsimafana" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Ambiky" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Ankalalobe" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Berevo" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Ankororoky" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Antsoha" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Belinta" },
  { REGION: "MENABE", DISTRICT: "BELO SUR TSIRIBIHINA", COMMUNE: "Tsaraotana" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Mahabo" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Ambia" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Ampanihy" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Analamitsivala" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Ankilizato" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Befotaka" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Beronono" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Bezezika" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Malaimbandy" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Mandabe" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Tsimazava" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Andranopasy" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Betsioky" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Soaserana" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Anontsibe Sakalava" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Manja" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Beharona" },
  { REGION: "MENABE", DISTRICT: "MANJA", COMMUNE: "Ankiliabo" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Ambatolahy" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Ampanihy" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Dabolava" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Itondy" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Manambina" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Manandaza" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Isalo" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Ankotrofotsy" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Anosimena" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Bemahatazana" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Andranomainty" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Miandrivazo" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Betsipolitra" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Ankavandra" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Soaloka" },
  { REGION: "MENABE", DISTRICT: "MIANDRIVAZO", COMMUNE: "Ankondromena" },
  { REGION: "MENABE", DISTRICT: "MORONDAVA", COMMUNE: "Morondava" },
  { REGION: "MENABE", DISTRICT: "MORONDAVA", COMMUNE: "Befasy" },
  { REGION: "MENABE", DISTRICT: "MORONDAVA", COMMUNE: "Belo sur Mer" },
  { REGION: "MENABE", DISTRICT: "MORONDAVA", COMMUNE: "Bemanonga" },
  {
    REGION: "MENABE",
    DISTRICT: "MORONDAVA",
    COMMUNE: "Bemanonga Marofangiliha",
  },
  { REGION: "MENABE", DISTRICT: "MORONDAVA", COMMUNE: "Analaiva" },
  { REGION: "MENABE", DISTRICT: "MAHABO", COMMUNE: "Ankilivalo" },
  {
    REGION: "MENABE",
    DISTRICT: "BELO SUR TSIRIBIHINA",
    COMMUNE: "Bemarivo Ankirondro",
  },
  {
    REGION: "MENABE",
    DISTRICT: "BELO SUR TSIRIBIHINA",
    COMMUNE: "Belo sur Tsiribihina",
  },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Anjialavabe" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Andapa" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Tanandava" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ambodimanga I" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ambinany Antsahamena" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Anoviara" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Andasibe  Kobahina" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ambalamanasy II" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Belaoka Lokoho" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Andrakata" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ambalavelona" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Belaoka Marovato" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Marovato" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ankiakabe Avaratra" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Matsohely" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Bealampona" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Ambohitralanana" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Ambalabe" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Ampanavoana" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Andampy" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Manakambahiny Ankavia" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Marofinaritra" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Vinanivao" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Antombana" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Sahantaha" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Ambinanifaho" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Antsahanoro" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Ampohibe" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Antananambo" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Antsambalahy" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Sarahandrano" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Ambalasatrana" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Ambinanin'Andravory" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Amboriala" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Ampisikinana" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Andrafainkona" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Andravory" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Fanambana" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Maromokotra Loky" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Nosibe" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Ampanefena" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Tsarabaria" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Bobakindro" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Daraina" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Milanoa" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Ambodivoara" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Ambohimitsinjo" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Analamaho" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Andratamarina" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Anjinjaomby" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Maroambihy" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Marogaona" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Marojala" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Anjangoveratra" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Bemanevika" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Tanambao Daoud" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Nosiarina" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Ambohimalaza" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Sambava" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Farahalana" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Morafeno" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Antsambaharo" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Andrembona" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Bevonotra" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Anjialava" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Antsahavaribe" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Mahasoa Antindra" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Ambodiampana" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Iharana" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Ampondra" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Ambodisambalahy" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Belambo" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Antindra" },
  { REGION: "SAVA", DISTRICT: "IHARANA", COMMUNE: "Antsirabe Avaratra" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Amboahangibe" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Ambatoafo" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Doany" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Andapabe" },
  { REGION: "SAVA", DISTRICT: "SAMBAVA", COMMUNE: "Andrahanjo" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ambodidivaina" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Ambodiangezoka" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Betsakotsako Andranotsara" },
  { REGION: "SAVA", DISTRICT: "ANDAPA", COMMUNE: "Andranomena" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Ampahana" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Antalaha" },
  { REGION: "SAVA", DISTRICT: "ANTALAHA", COMMUNE: "Lanjarivo" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Ambolobozo" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Ambaliha" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Befotaka" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Analalava" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Antonibe" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Ambarijeby Atsimo" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Marovatolena" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Andribavontsina" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Mahadrodroka" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Maromandia" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Andrevorevo" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Marovantaza" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Angoaka Atsimo" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Ambodimadiro" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Andreba" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Ambodimandresy" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Ankerika" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Antsohihy" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Anjalazala" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Ambodimanary" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Maroala" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Mangindrano" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambararatabe Avaratra" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Anjozoromadosy" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Beandrarezona" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Bealanana" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Analila" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambalaromba" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ankazotokana" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Antsamaka" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Marotolana" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambatosia" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambodiampana" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambararata Sofia" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambodisikidy" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambohimisondrotra" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambodiadabo" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Antsiradava" },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Ambolidibe Atsinanana",
  },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Ambararata" },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Ankarongana" },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Befandriana Avaratra",
  },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Antsakanalabe",
  },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Ambodimotso Atsimo",
  },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Tsarahonenana",
  },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Ambodimotso Ambony",
  },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Belalona" },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Antsakabary" },
  {
    REGION: "SOFIA",
    DISTRICT: "BEFANDRIANA AVARATRA",
    COMMUNE: "Matsondakana",
  },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Maroamalona" },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Morafeno" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Ambodisakoana" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Leanja" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Tsiningia" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Tsinjomitondraka" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Tsaratanana I" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Marovato" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Tsarahasina" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Maevaranohely" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Ambodimahabibo" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Ihobaka" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Ambatomilahatrano" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Andranomeva" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Amparihibe" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Ambanjabe" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Ambodihazoambo" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Komajia" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Mampikony II" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Bekoratsaka" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Ankiririky" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Betaramahamay" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Agnovolava Avaratra" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Mampikony I" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Pont Sofia" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ankiabe Salohy" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambilombe" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Antsatramidola" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Kalandy" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ankiakabe Fanoko" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambaripaika" },
  {
    REGION: "SOFIA",
    DISTRICT: "MANDRITSARA",
    COMMUNE: "Ambodiamontana Kianga",
  },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Andratamarina" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Andohajango" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambohisoa" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Mandritsara" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambariokorano" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Antsirabe Afovoany" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Marotandrano" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Antanambaon'Amberina" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ampatakamaroreny" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Antsoha" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambinany Fango" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Antsiatsiaka" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Ankaramy" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Antananivo Ambony" },
  { REGION: "SOFIA", DISTRICT: "ANALALAVA", COMMUNE: "Bejofo" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Antsahabe" },
  { REGION: "SOFIA", DISTRICT: "BEFANDRIANA AVARATRA", COMMUNE: "Tsiamalao" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambovonomby" },
  { REGION: "SOFIA", DISTRICT: "BEALANANA", COMMUNE: "Ambatoria Atsinanana" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Ampandriakilandy" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Anahidrano" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Anjiamangirana" },
  { REGION: "SOFIA", DISTRICT: "ANTSOHIHY", COMMUNE: "Ambalafaminty" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Boriziny I" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Boriziny II" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Andranomena I" },
  { REGION: "SOFIA", DISTRICT: "BORIZINY", COMMUNE: "Ambodivongo" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Anjiabe" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambalakirajy" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Tsarajomoka" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Antanandava" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Tsaratanana" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Amboaboa" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Manampaneva" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Ampasimatera" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Ambohitoaka" },
  { REGION: "SOFIA", DISTRICT: "MAMPIKONY", COMMUNE: "Malakialina" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Amborondolo" },
  { REGION: "SOFIA", DISTRICT: "MANDRITSARA", COMMUNE: "Ambodiadabo" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Ambatolahy" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Ambatomiady" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Ambodiriana" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTANIFOTSY",
    COMMUNE: "Ampitatafika",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Antsahalava" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTANIFOTSY",
    COMMUNE: "Antsampandrano",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTANIFOTSY",
    COMMUNE: "Ambatotsipihina",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTANIFOTSY",
    COMMUNE: "Ambohimandroso",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTANIFOTSY",
    COMMUNE: "Soamanandrariny",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Antanifotsy" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Andranofito" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Anjanamanga" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE I", COMMUNE: "Antsirabe" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Alakamisy" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Ibity" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Ambano" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Ambatomena" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Ambohibary" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Ambohimiarivo",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Antanimandry",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Belazao" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Mangarano" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Sahanivotry",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Soanindrariny",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Vinaninkarena",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Mandrosohasina",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Ambohidranandriana",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Ambohitsimanova",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Manandona" },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTSIRABE II", COMMUNE: "Antanambao" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Tsarahonenana",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Alarobia Bemaha" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Antsoantany",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTSIRABE II",
    COMMUNE: "Andranomanelatra",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Betafo" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Ambatonikolahy" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Ambohimanambola" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Ranomafana" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Andrembesoa" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "BETAFO",
    COMMUNE: "Anosiarivo Manapa",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Antohobe" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Antsoso" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Inanantonana" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "BETAFO",
    COMMUNE: "Ambohijato Mandritsara",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Soavina" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Tritriva" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "BETAFO",
    COMMUNE: "Alakamisy Anativato",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Mahaiza" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Manohisoa" },
  { REGION: "VAKINANKARATRA", DISTRICT: "BETAFO", COMMUNE: "Ambohimasina" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "BETAFO",
    COMMUNE: "Alakamisy Marososona",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "FARATSIHO", COMMUNE: "Faratsiho" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "FARATSIHO",
    COMMUNE: "Ambatofotsy Ambohiborona",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "FARATSIHO",
    COMMUNE: "Antsampanimahazo",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "FARATSIHO",
    COMMUNE: "Vinaninony Avaratra",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "FARATSIHO", COMMUNE: "Miandrarivo" },
  { REGION: "VAKINANKARATRA", DISTRICT: "FARATSIHO", COMMUNE: "Ramainandro" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "FARATSIHO",
    COMMUNE: "Ambatoasana Valabetokana",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "FARATSIHO",
    COMMUNE: "Vinaninony Atsimo",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "AMBATOLAMPY", COMMUNE: "Ambatolampy" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Ambatondrakalavao",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Ambodifarihy Fenomanana",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Andranovelona",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Andriambilany",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "AMBATOLAMPY", COMMUNE: "Antakasina" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Alarobia Antanamalaza",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Antanimasaka Tsaramiafara",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Antsampandrano",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "AMBATOLAMPY", COMMUNE: "Behenjy" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Manjakatompo",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "AMBATOLAMPY", COMMUNE: "Morarano" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Sabotsy Namatoana",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Tsiafajavona Ankaratra",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "AMBATOLAMPY", COMMUNE: "Tsinjoarivo" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Andriatsivalana",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Belambo Firaisana",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "AMBATOLAMPY", COMMUNE: "Andravola" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "AMBATOLAMPY",
    COMMUNE: "Ambohipihaonana",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Ankazomiriotra" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Fidirana" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Maromandray" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Vasiana" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Vinany" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Tanambao Ambary" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Betsohana" },
  { REGION: "VAKINANKARATRA", DISTRICT: "MANDOTO", COMMUNE: "Mandoto" },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "MANDOTO",
    COMMUNE: "Anjoma Ramartina",
  },
  {
    REGION: "VAKINANKARATRA",
    DISTRICT: "ANTANIFOTSY",
    COMMUNE: "Ambohitompoina",
  },
  { REGION: "VAKINANKARATRA", DISTRICT: "ANTANIFOTSY", COMMUNE: "Belanitra" },
  { REGION: "VAKINANKARATRA", DISTRICT: "FARATSIHO", COMMUNE: "Andranomiady" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Ifanadiana",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Ranomafana",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IFANADIANA", COMMUNE: "Ambiabe" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Ambohimanga Atsimo",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Ambohimiera",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Ampasinambo",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Analampasina",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Androrangavola",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Antaretra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Antsindra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Fasintsara",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Kelilalina",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Maroharatra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Marotoko",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IFANADIANA",
    COMMUNE: "Tsaratanana",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IKONGO",
    COMMUNE: "Ambohimisafy",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Andefampony" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Ankarimbelo" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Antodinga" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Belemoka" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Ifanirea" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Kalafotsy" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Maromiandra" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Sahalanona" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IKONGO",
    COMMUNE: "Antanakambana",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Tolongoina" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IKONGO",
    COMMUNE: "Tsifenokatana",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IKONGO",
    COMMUNE: "Ambolomadinika",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Ambatofotsy" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "IKONGO", COMMUNE: "Ikongo" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IKONGO",
    COMMUNE: "Manampatrana",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "IKONGO",
    COMMUNE: "Ambinanitromby",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Manakara" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Marofarihy",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ambahatrazo",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Ambahive" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ambalavero",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Amboanjo Ifaho",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ambohitsara",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ampasimboraka",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Analavory" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Anorombato",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Anteza" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Fenomby" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Kianjanomby",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Mahabako" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Mahamaibe" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Mangatsiotra",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Manjarivo" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Nosiala" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Ionilahy" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Saharefo" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Sahasinaka",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Sakoagna" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Sorombo" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Tataho" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Vohilava" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Vohimanitra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Vohimasina Atsimo",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Vohimasy" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ambandrika",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Vinanitelo",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ampasimpotsy Atsimo",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Bekatra" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Lokomby" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ambohitrova",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ambalaroka",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Vatana" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Betampona" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Vohimasina Avaratra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Ampasimanjeva",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Mitanty" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Nihaonana  Ambany",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Ambotaka" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Mavorano" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Mizilo Gara",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANAKARA", COMMUNE: "Ambila" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Amborondra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANAKARA",
    COMMUNE: "Sahanambohitra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Mananjary",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Kianjavato",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Ambalahosy",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Ambodinonoka",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Ambohimiarina II",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Ambohinihaonana",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Andonabe" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Andranambolava",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Andranomavo",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Ankatafana",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Anosimparihy",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Antaretra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Antsenavolo",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Mahatsara",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Mahatsara Atsimo",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Mahavoky Avaratra",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Manakana" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Marofototra",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Marokarima",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Marosangy",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Morafeno" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Sandrohy" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Tsaravary",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Tsiatosika",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Vatohandrina",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Vohilava" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "MANANJARY",
    COMMUNE: "Ambohitsara Atsinanana",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Mahela" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "MANANJARY", COMMUNE: "Namorona" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Nosy Varika",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Ambakobe",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Angodongodona",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "NOSY VARIKA", COMMUNE: "Befody" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Fanivelona",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Soavina",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Vohilava",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Antanambao",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Ambodiara",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Ampasinambo",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "NOSY VARIKA", COMMUNE: "Andara" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "NOSY VARIKA", COMMUNE: "Ambahy" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Fiadanana",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Ambodiriana Sahafary",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Ambodilafa",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Vohidroa",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Sahavato",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Androrangavola",
  },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "NOSY VARIKA",
    COMMUNE: "Vohitrandriana",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Vohipeno" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Amborobe" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Andemaka" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "VOHIPENO",
    COMMUNE: "Ankarimbary",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Anoloka" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "VOHIPENO",
    COMMUNE: "Antananambo",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Ivato" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Lanivoa" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Mahasoabe" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "VOHIPENO",
    COMMUNE: "Mahazoarivo",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Nato" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Onjatsy" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Savana" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "VOHIPENO",
    COMMUNE: "Vohitrindry",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Sahalava" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Vohilany" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Vohindava" },
  {
    REGION: "VATOVAVY FITOVINANY",
    DISTRICT: "VOHIPENO",
    COMMUNE: "Zafindrafady",
  },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Ilakatra" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Mahabo" },
  { REGION: "VATOVAVY FITOVINANY", DISTRICT: "VOHIPENO", COMMUNE: "Ifatsy" },
];
