import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import Person from "../components/Person";
import { lieu } from "../components/lieu";

export default function Adduser() {
  const { user, setAlert } = useContext(AppContext);
  const [pg, setPg] = useState(null);
  const regionL = [...new Set(lieu.map((i) => i.REGION))];
  const [districtL, setDistrictL] = useState([]);
  const [communeL, setCommuneL] = useState([]);
  const [listPg, setListPg] = useState([]);
  const [listPers, setListPers] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useRef(null);

  async function getListPg() {
    try {
      const res = await fetch(
        process.env.REACT_APP_API +
          "/petit-groupe/all-champion/" +
          user.id_champion,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
          },
        }
      );
      const result = await res.json();
      setListPg(result.data.all);
    } catch (error) {
      console.log(error);
    }
  }
  async function getListPers() {
    try {
      if (pg === null) return;
      const res = await fetch(
        process.env.REACT_APP_API + "/petit-groupe/all-members/" + pg,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
          },
        }
      );
      const result = await res.json();
      setListPers(result.data.all);
    } catch (error) {
      console.log(error);
    }
  }
  const handleChange = (e, c) => {
    setPg(c.props.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData(form.current);
    data.append("id_pg", pg);
    setLoading(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_API + "/petit-groupe/add-member",
        {
          method: "POST",
          body: data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
          },
        }
      );
      const result = await res.json();
      if (result.success) {
        getListPers();
        setAlert({ type: "success", message: "Mpikambana vaovao voaray" });
        setOpen(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", message: "Tsy tontosaina ny fangatahana" });
      setLoading(false);
    }
  };
  useEffect(() => {
    getListPers();
    // eslint-disable-next-line
  }, [pg]);
  useEffect(() => {
    getListPg();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="container flex flex-col items-center">
      <div className="w-full flex justify-between mb-3 gap-3">
        <FormControl fullWidth>
          <InputLabel id="pg-label">Vondrona madinika</InputLabel>
          <Select
            labelId="pg-label"
            id="pg-input"
            value={pg ?? ""}
            label="Vondrona madinika"
            onChange={handleChange}
          >
            {listPg.length !== 0 ? (
              listPg.map((i) => (
                <MenuItem value={i.id_pg} key={i.id_pg}>
                  {i.nom_pg}
                </MenuItem>
              ))
            ) : (
              <div className="flex justify-center w-full">
                <p>Tsy manana vondrona madinika</p>
              </div>
            )}
          </Select>
        </FormControl>
        {pg && (
          <Button
            variant="contained"
            disableElevation
            onClick={() => setOpen(true)}
          >
            Hampiditra
          </Button>
        )}
      </div>
      {!pg ? (
        <i>Misafidiana vondrona madinika iray</i>
      ) : listPers.length === 0 ? (
        <i>Tsy mbola misy mpikambana</i>
      ) : (
        <div className="w-full bg-gray-100 p-3 flex flex-wrap gap-3 rounded">
          {listPers.map((p) => (
            <Person user={p} key={p.id_membre} refresh={getListPers} />
          ))}
        </div>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <div className="flex flex-col p-4 gap-4">
          <div className="flex justify-between">
            <h1 className="font-bold">Mpikambana</h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <form
            onSubmit={handleSubmit}
            ref={form}
            className="flex flex-col gap-3 items-center"
          >
            <TextField name="nom" required label="Anarana sy fanampiny:" />
            <RadioGroup name="genre" defaultValue={"M"}>
              <div className="flex">
                <div className="flex items-center">
                  <label htmlFor="male">Lehilahy</label>
                  <Radio id="male" value={"M"} />
                </div>
                <div className="flex items-center">
                  <label htmlFor="femelle">Vehivavy</label>
                  <Radio id="femelle" value={"F"} />
                </div>
              </div>
            </RadioGroup>
            <TextField name="age" required label="Taona:" />
            <div className="input">
              <label>
                Ny Toerana misy anao ? <span>*</span>
              </label>
              <div className="flex flex-col gap-2">
                <div className="flex flex-wrap gap-3 justify-between">
                  <Autocomplete
                    options={regionL}
                    onChange={(e, v) => {
                      setDistrictL([
                        ...new Set(
                          lieu
                            .filter((i) => i.REGION === v)
                            .map((i) => i.DISTRICT)
                        ),
                      ]);
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        sx={{ width: 150 }}
                        label="Faritra :"
                        name="region"
                        required
                        id="region"
                      />
                    )}
                  />
                  <Autocomplete
                    options={districtL}
                    onChange={(e, v) => {
                      setCommuneL([
                        ...new Set(
                          lieu
                            .filter((i) => i.DISTRICT === v)
                            .map((i) => i.COMMUNE)
                        ),
                      ]);
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        sx={{ width: 150 }}
                        label="Distrika :"
                        name="district"
                        required
                        id="district"
                      />
                    )}
                  />
                  <Autocomplete
                    options={communeL}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        sx={{ width: 150 }}
                        label="Kaominina :"
                        name="commune"
                        required
                        id="commune"
                      />
                    )}
                  />
                </div>
                <div className="flex justify-between w-full gap-3">
                  <TextField
                    name="fokontany"
                    label="Fokontany :"
                    required
                    id="fokontany"
                  />
                  <TextField
                    name="village"
                    label="Vohitra :"
                    required
                    id="village"
                  />
                </div>
              </div>
            </div>
            <TextField name="occupation" required label="Asa atao :" />
            <FormControl fullWidth>
              <InputLabel required id="etude">
                Ambaratongam-pianarana
              </InputLabel>
              <Select
                labelId="etude"
                id="pg-input"
                name="niveau_etude"
                label="Ambaratongam-pianarana"
                defaultValue={"0"}
                required
              >
                <MenuItem value="Maitrise">Maitrise</MenuItem>
                <MenuItem value="Licence">Licence</MenuItem>
                <MenuItem value="Bacc">Baccalauréat</MenuItem>
                <MenuItem value="BEPC">BEPC</MenuItem>
                <MenuItem value="CEPE">CEPE</MenuItem>
                <MenuItem value="0">Tsy nianatra mihitsy</MenuItem>
              </Select>
            </FormControl>
            <TextField name="phone" label="Laharana finday:" />
            <TextField name="email" label="Adresy mailaka:" />
            <LoadingButton
              loading={loading}
              type="submit"
              sx={{
                background: "var(--active)",
                color: "white",
                textTransform: "none",
                fontWeight: "bold",
                fontFamily: "Open sans",
                "&:hover": {
                  background: "var(--theme)",
                },
              }}
              variant="outlined"
            >
              Hampiditra
            </LoadingButton>
          </form>
        </div>
      </Dialog>
    </div>
  );
}
