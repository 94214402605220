export const semis_types = [
  "Roy, Acacia mangium, mangium",
  "Kesika, Pinus spp., pins",
  "Kininina, Eucalyptus globulus, Eucalyptus",
  "Mahabibo, Anacardium, anacardier",
  "Voamadilo,Tamarindus indica, tamarinier",
  "Makira, Loranjy, Citrus spp.  agrumes",
  "Manga, Mangifera indica, manguier",
  "Faralotra, Grevillea robusta,  grevillea",
  "Ananambo, Moringa oleifera, moringa",
  "Ravintsara, Cinnamomum camphora, ravintsaa",
  "Filao, Casuarina equisetifolia, filao",
  "Zavoka, Persea americana, avocatier",
  "Goavy, Psidium guajava, goyavier",
  "Neem, Azadirachta indica, Neem",
  "Mantaly, Terminalia spp, terminalia",
  "Mimosa, Acacia auriculiformis",
  "Karasol/Konokony, Annona spp",
  "Bonara, Albizzia lebbeck, albizzia",
  "Cassia, Cassia siamea, cassia",
  "Mandravasarotra, Cinnamosma madagascariensis",
  "Zamborozana, Syzygium jambos, pomme rose",
  "Pibasy, Mespilus germanica, neflier",
  "Baie rose, Mespilus germanica, Baie rose",
  "Ampalibe, Altocarpus heterophyllus, Jacquier",
  "Laotisy, Litchi chinensis, Litchi",
  "Kaki, Diospyros kaki, Kaki",
  "Landemy, Anthocleista madagascariensis",
  "Aiavy, Ficus trichopoda, Figue",
  "Nonoka, Ficus pyrifolia, Figue",
  "Antoravina/Tohiravina, Phyllarthron madagascariensis",
  "Rotra, Eugenia jambolana",
  "Tapia, Uapaca bojeri, Tapia",
  "Lalona, Weinmannia madagascariensis",
  "Amontana/Aviavindahy, Ficus baroni",
  "Harongana, Harongana madagascariensis",
  "Famelona, Gambeya madagascariensis Lec",
];
