import { Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../App";

export default function Person({ user, refresh }) {
  const { setAlert } = useContext(AppContext);
  const deletePerson = async (id) => {
    try {
      const res = await fetch(
        process.env.REACT_APP_API + "/champion/membre/" + id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
          },
        }
      );
      const result = await res.json();
      if (result.success) {
        setAlert({ type: "success", message: "Tontosa ny famafana!" });
        refresh();
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", message: "Misy tsy fahatomombanana!" });
    }
  };
  return (
    <div
      className="p-4 bg-white w-[300px] rounded-xl shadow-md hover:shadow-xl cursor-pointer relative"
      style={{ transition: ".2s" }}
    >
      <Tooltip title="Supprimer">
        <IconButton
          style={{
            position: "absolute",
            top: -5,
            right: -10,
            background: "white",
            boxShadow: "0 0 5px 0 rgba(0,0,0,.2)",
          }}
          onClick={() => deletePerson(user.id_membre)}
        >
          <Close />
        </IconButton>
      </Tooltip>
      <h1 className="font-bold text-[20px]">{user.nom}</h1>
      <p>
        Taona: <strong>{user.age}</strong>
      </p>
      <p>
        Asa: <strong>{user.occupation}</strong>
      </p>
      <p>
        Finday: <strong>{user.phone ?? "-"}</strong>
      </p>
    </div>
  );
}
