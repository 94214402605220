import { createTheme } from "@mui/material";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#728c6b",
    },
    mode: "light",
  },
  components: {
    MuiTextField: {
      defaultProps: {
        sx: {
          width: "100%",
        },
      }
    },
    MuiButton: {
      defaultProps: {
        sx: {
          borderRadius: "7px",
          textTransform: "none",
          fontWeight: "bold",
          fontFamily: "var(--font-family)",
        },
      }
    },
  }
});
