import {
  Autocomplete,
  TextField,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState, useRef, useContext } from "react";
import { lieu } from "../components/lieu";
import { LoadingButton } from "@mui/lab";
import { AppContext } from "../App";

export default function Contact() {
  const { setAlert } = useContext(AppContext);
  const regionL = [...new Set(lieu.map((i) => i.REGION))];
  const [districtL, setDistrictL] = useState([]);
  const [communeL, setCommuneL] = useState([]);
  const [loading, setLoading] = useState(false);
  const [x, setX] = useState((Math.random().toFixed(1) * 10).toFixed(0));
  const [y, setY] = useState((Math.random().toFixed(1) * 10).toFixed(0));
  const form = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const f = form.current;
    if (parseInt(x) + parseInt(y) !== parseInt(f.z.value)) {
      console.log("Erreur captcha");
      setX((Math.random().toFixed(1) * 10).toFixed(0));
      setY((Math.random().toFixed(1) * 10).toFixed(0));
      setAlert({ type: "warning", message: "Erreur d'envoi du captcha" });
      return;
    }
    const data = new FormData(f);
    setLoading(true);
    try {
      await fetch(process.env.REACT_APP_API + "/jointeraka", {method: 'POST', body: data});
      setAlert({ type: "success", message: "Tontosa ny fangatahana" });
      f.reset();
    } catch (error) {
      setAlert({ type: "error", message: "Erreur d'envoi du demande" });
    }
    setX((Math.random().toFixed(1) * 10).toFixed(0));
    setY((Math.random().toFixed(1) * 10).toFixed(0));
    setLoading(false);
  };
  return (
    <div className="container">
      <form
        ref={form}
        className="flex flex-col items-center gap-[20px]"
        onSubmit={handleSubmit}
      >
        <h1
          style={{
            textTransform: "uppercase",
            fontWeight: "bolder",
            color: "var(--active)",
            textAlign: "center",
            width: "100%",
          }}
        >
          Taratasy fangatahana hiditra ho mpikambana TERAKA
        </h1>
        <div className="input">
          {/* <label htmlFor="nom">
            Anarana : <span>*</span>
          </label> */}
          <TextField id="nom" label="Anarana" name="nom" required />
        </div>
        <div className="input">
          {/* <label htmlFor="age">
            Taona : <span>*</span>
          </label> */}
          <TextField id="age" label="Taona" name="age" type="number" required />
        </div>
        <RadioGroup name="genre" defaultValue={"M"}>
          <div className="flex">
            <div className="flex items-center">
              <label htmlFor="male">Lehilahy</label>
              <Radio id="male" value={"M"} />
            </div>
            <div className="flex items-center">
              <label htmlFor="femelle">Vehivavy</label>
              <Radio id="femelle" value={"F"} />
            </div>
          </div>
        </RadioGroup>
        <div className="input">
          {/* <label htmlFor="occupation">Asa atao : <span>*</span></label> */}
          <TextField
            name="education"
            id="education"
            label="Sokajim-pianarana farany"
            required
          />
        </div>
        <div className="input">
          {/* <label htmlFor="occupation">Asa atao : <span>*</span></label> */}
          <TextField
            name="occupation"
            id="occupation"
            label="Asa atao"
            required
          />
        </div>
        <div className="input">
          <label>
            Ny Toerana misy anao ? <span>*</span>
          </label>
          <div className="flex flex-wrap justify-evenly gap-3">
            <Autocomplete
              options={regionL}
              onChange={(e, v) => {
                setDistrictL([
                  ...new Set(
                    lieu.filter((i) => i.REGION === v).map((i) => i.DISTRICT)
                  ),
                ]);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{ width: 300 }}
                  label="Faritra :"
                  name="region"
                  required
                  id="region"
                />
              )}
            />
            <Autocomplete
              options={districtL}
              onChange={(e, v) => {
                setCommuneL([
                  ...new Set(
                    lieu.filter((i) => i.DISTRICT === v).map((i) => i.COMMUNE)
                  ),
                ]);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{ width: 300 }}
                  label="Distrika :"
                  name="district"
                  required
                  id="district"
                />
              )}
            />
            <Autocomplete
              options={communeL}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{ width: 300 }}
                  label="Kaominina :"
                  name="commune"
                  required
                  id="commune"
                />
              )}
            />
            <TextField
              sx={{ width: 300 }}
              name="fokontany"
              label="Fokontany :"
              required
              id="fokontany"
            />
            <TextField
              sx={{ width: 300 }}
              name="village"
              label="Vohitra :"
              required
              id="village"
            />
          </div>
        </div>
        <div className="input">
          {/* <label htmlFor="comment">
            Ahoana no nafantaranao ny TERAKA <span>*</span>
          </label> */}
          <TextField
            name="comment"
            label="Nahafantarana an'i TERAKA"
            required
          />
        </div>
        <div className="input">
          <label>
            Efa nanaraka ny fiofanana tamin'ny rohy ve ? <span>*</span>
          </label>
          <RadioGroup name="suivi_formation" defaultValue={0}>
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-formation">Eny</label>
                <Radio id="oui-formation" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-formation">Tsia</label>
                <Radio id="non-formation" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="input">
          <label>
            Afaka mamorona vondrona madinika ve ? <span>*</span>
          </label>
          <RadioGroup name="peut_former_pg" defaultValue={0}>
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-pg">Eny</label>
                <Radio id="oui-pg" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-pg">Tsia</label>
                <Radio id="non-pg" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="input">
          <label>
            Manana tany hambolena hazo ve ? <span>*</span>
          </label>
          <RadioGroup name="avoir_terrain" defaultValue={0}>
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-terrain">Eny</label>
                <Radio id="oui-terrain" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-terrain">Tsia</label>
                <Radio id="non-terrain" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="input">
          <label>
            Manana zanak'hazo Teraka ve ? <span>*</span>
          </label>
          <RadioGroup name="plantation_teraka" defaultValue={0}>
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-plantation">Eny</label>
                <Radio id="oui-plantation" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-plantation">Tsia</label>
                <Radio id="non-plantation" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="input">
          <label htmlFor="phone">
            Laharana finday : <span>*</span>
          </label>
          <TextField name="phone" id="phone" label="Finday" required />
        </div>
        <div className="flex gap-3 ">
          <input
            type="number"
            name="captcha1"
            value={x}
            className="captcha"
            disabled
            required
          />
          <p>&nbsp;+&nbsp;</p>
          <input
            type="number"
            name="captcha2"
            className="captcha"
            value={y}
            disabled
            required
          />
          <p>&nbsp;=&nbsp;</p>
          <input type="number" name="z" className="captcha" required />
        </div>
        <div className="mb-4">
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{
              background: "var(--active)",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "Open sans",
              "&:hover": {
                background: "var(--theme)",
              },
            }}
            variant="outlined"
          >
            Handefa
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}
