import { AddAPhotoOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Radio, RadioGroup, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../App";
import { lieu } from "../components/lieu";
import { semis_types } from "../semis";

export default function Ajout() {
  const { user, setAlert } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [semis, setSemis] = useState(0);
  const [plante_arbre, setPlanteArbre] = useState(0);
  const form = useRef(null);
  const regionL = [...new Set(lieu.map((i) => i.REGION))];
  const [districtL, setDistrictL] = useState([]);
  const [communeL, setCommuneL] = useState([]);
  const [many, setMany] = useState([]);
  const [pepfiles, setPepfiles] = useState(null);
  const [many2, setMany2] = useState([]);
  const [pepfiles2, setPepfiles2] = useState(null);

  const formSubmit = async (e) => {
    e.preventDefault();
    const f = form.current;
    const formData = new FormData(f);
    formData.append("id_champion", user.id_champion);
    if (formData.get("avoir_terrain_pepiniere") === "1") {
      formData.set(
        "type_semis",
        [...many, ...formData.get("autre_type").split(",")].join(";")
      );
    }
    for (let i = 0; i < pepfiles.length; i++) {
      formData.append("files[]", pepfiles[i]);
    }
    if (formData.get("plante_arbre") === "1") {
      formData.set(
        "type_arbre",
        [...many2, ...formData.get("autre_type2").split(",")].join(";")
      );
    }
    for (let i = 0; i < pepfiles2.length; i++) {
      formData.append("files2[]", pepfiles2[i]);
    }
    setLoading(true);
    try {
      await axios({
        method: "POST",
        url: process.env.REACT_APP_API + "/petit-groupe/add",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tokenChampion"),
        },
      });
      setAlert({ type: "success", message: "Tontosa ny fampidirana!" });
      setMany([]);
      setMany2([]);
      f.reset();
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", message: "Avereno afaka fotoana fohy!" });
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <form
        onSubmit={formSubmit}
        ref={form}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: "20px",
        }}
        encType="multipart/form-data"
      >
        <h1
          style={{
            textTransform: "uppercase",
            fontWeight: "bolder",
            color: "var(--active)",
            textAlign: "center",
            width: "100%",
          }}
        >
          Taratasy Fisoratana Anarana ho an'ny Vondrona Madinika
        </h1>
        <div className="banniere">
          <p>Fanontaniana momba ilay Vondrona Madinika TERAKA vaovao</p>
        </div>
        <div className="input">
          <label htmlFor="nom_pg">
            Iza no anaran'ilay Vondrona Madinika vaovao hiditra ao amin’ny
            TERAKA ?
          </label>
          <TextField label="Anarana :" required name="nom_pg" id="nom_pg" />
        </div>
        <div className="input">
          <label>Ny Toerana misy an’ilay Vondrona Madinika ?</label>
          <div className="flex flex-wrap justify-evenly gap-3">
            <Autocomplete
              options={regionL}
              onChange={(e, v) => {
                setDistrictL([
                  ...new Set(
                    lieu.filter((i) => i.REGION === v).map((i) => i.DISTRICT)
                  ),
                ]);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{ width: 300 }}
                  label="Faritra :"
                  name="region"
                  required
                  id="region"
                />
              )}
            />
            <Autocomplete
              options={districtL}
              onChange={(e, v) => {
                setCommuneL([
                  ...new Set(
                    lieu.filter((i) => i.DISTRICT === v).map((i) => i.COMMUNE)
                  ),
                ]);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{ width: 300 }}
                  label="Distrika :"
                  name="district"
                  required
                  id="district"
                />
              )}
            />
            <Autocomplete
              options={communeL}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{ width: 300 }}
                  label="Kaominina :"
                  name="commune"
                  required
                  id="commune"
                />
              )}
            />
            <TextField
              sx={{ width: 300 }}
              name="fokontany"
              label="Fokontany :"
              required
              id="fokontany"
            />
          </div>
        </div>
        <div className="input">
          <label>Laharana finday :</label>
          <div className="flex flex-wrap justify-evenly gap-3">
            <TextField
              sx={{ width: 300 }}
              name="phone1"
              label="Finday 1 :"
              required
              type="tel"
              id="phone1"
            />
            <TextField
              sx={{ width: 300 }}
              name="phone2"
              label="Finday 2 :"
              required
              type="tel"
              id="phone2"
            />
            <TextField
              sx={{ width: 300 }}
              name="phone3"
              label="Finday 3 :"
              required
              type="tel"
              id="phone3"
            />
          </div>
        </div>
        <div className="input">
          <h4 style={{ margin: 0 }}>
            Alaivo sary ilay Vondrona Madinika an’ny TERAKA vaovao ary ampidiro
            eto ilay sary :
          </h4>
          <label htmlFor="photo_pg">
            <AddAPhotoOutlined />
          </label>
          <input
            type="file"
            name="photo_pg"
            id="photo_pg"
            accept="image/*"
            required
          />
        </div>
        <div className="banniere">
          <p>Mila fenoina tsara</p>
        </div>
        <div className="input">
          <label>
            Avy amin'ny fianakaviana 3 samihafa farafahakeliny ve ireo
            mpikambana ao amin'ilay vondrona ? <span>*</span>
          </label>
          <RadioGroup name="issue_famille_different" defaultValue={0}>
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-famille">Eny</label>
                <Radio id="oui-famille" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-famille">Tsia</label>
                <Radio id="non-famille" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="input">
          <label>
            Nanaraka ireo fiofanana ny amin’ny fomba fidirana ao amin'ny TERAKA
            ve ilay Vondrona Mandinika? <span>*</span>
          </label>
          <RadioGroup name="suivi_formation" defaultValue={0}>
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-formation">Eny</label>
                <Radio id="oui-formation" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-formation">Tsia</label>
                <Radio id="non-formation" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="input">
          <label>
            Manana tany hambolena zana-kazo ve ? <span>*</span>
          </label>
          <RadioGroup
            name="avoir_terrain_pepiniere"
            value={semis}
            onChange={(e, v) => {
              setSemis(parseInt(v));
              setMany([]);
            }}
          >
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-pepiniere">Eny</label>
                <Radio id="oui-pepiniere" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-pepiniere">Tsia</label>
                <Radio id="non-pepiniere" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        {semis === 1 && (
          <>
            <div className="input">
              <TextField
                name="nb_semis"
                type="number"
                label="Isan'ny zana-kazo :"
                required
              />
            </div>
            <div className="input">
              <label htmlFor="type_semis">
                Inona avy ireo karazana hazo nambolena ?
              </label>
              <Autocomplete
                multiple
                options={semis_types}
                onChange={(e, v) => setMany(v)}
                value={many}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    sx={{ width: "100%" }}
                    label="Karazana zana-kazo :"
                    name="type_semis"
                    id="type_semis"
                  />
                )}
              />
              <TextField
                name="autre_type"
                label="Hafa (sarahana amin'ny faingo):"
                type="text"
              />
            </div>
            <div className="input">
              <h4 style={{ margin: 0 }}>
                Alaivo sary ilay hazo ao amin’ny tanin-kazo. Makà sary 5 ao
                anaty faribolana mba hanehoana ny tanin-kazo iray manontolo.
              </h4>
              <label htmlFor="photo_pepiniere">
                <AddAPhotoOutlined />
              </label>
              <input
                type="file"
                name="photo_pepiniere"
                id="photo_pepiniere"
                multiple="multiple"
                onChange={(e) => setPepfiles(e.target.files)}
                required
                accept="image/*"
              />
            </div>
          </>
        )}
        {semis === 0 && (
          <div className="input">
            <h4 style={{ margin: 0 }}>
              Alaivo sary ilay tany hametrahana ny zana-kazo.
            </h4>
            <label htmlFor="photo_pepiniere">
              <AddAPhotoOutlined />
            </label>
            <input
              type="file"
              name="photo_pepiniere"
              id="photo_pepiniere"
              multiple="multiple"
              onChange={(e) => setPepfiles(e.target.files)}
              required
              accept="image/*"
            />
          </div>
        )}
        <div className="banniere">
          <p>Momban'ny tanin-kazo</p>
        </div>
        <div className="input">
          <label>
            Efa Namboly hazo Teraka ve ? <span>*</span>
          </label>
          <RadioGroup
            name="plante_arbre"
            value={plante_arbre}
            onChange={(e, v) => {
              setPlanteArbre(parseInt(v));
              setMany2([]);
            }}
          >
            <div className="flex">
              <div className="flex items-center">
                <label htmlFor="oui-arbre">Eny</label>
                <Radio id="oui-arbre" value={1} />
              </div>
              <div className="flex items-center">
                <label htmlFor="non-arbre">Tsia</label>
                <Radio id="non-arbre" value={0} />
              </div>
            </div>
          </RadioGroup>
        </div>
        {plante_arbre === 1 && (
          <>
            <div className="input">
              <TextField
                name="nb_arbre"
                type="number"
                label="Isan'ny hazo :"
                required
              />
            </div>
            <div className="input">
              <label>
                Daty nambolena ny hazo : <span>*</span>
              </label>
              <TextField name="plantation_date" type="date" required />
            </div>
            <div className="input">
              <label htmlFor="type_arbre">
                Inona avy ireo karazana hazo nambolena ?
              </label>
              <Autocomplete
                multiple
                options={semis_types}
                onChange={(e, v) => setMany2(v)}
                value={many2}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    sx={{ width: "100%" }}
                    label="Karazana hazo :"
                    name="type_arbre"
                    id="type_arbre"
                  />
                )}
              />
              <TextField
                name="autre_type2"
                label="Hafa (sarahana amin'ny faingo):"
                type="text"
              />
            </div>
          </>
        )}
        <div className="input">
          <h4 style={{ margin: 0 }}>
            Alaivo sary ilay tany hambolena hazo. Makà sary 5 ao anaty
            faribolana mba hanehoana ny tany iray manontolo.
          </h4>
          <label htmlFor="photo_pepiniere">
            <AddAPhotoOutlined />
          </label>
          <input
            type="file"
            name="photo_arbre"
            id="photo_arbre"
            multiple="multiple"
            onChange={(e) => setPepfiles2(e.target.files)}
            required
            accept="image/*"
          />
        </div>
        <div className="mb-4">
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{
              background: "var(--active)",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "Open sans",
              "&:hover": {
                background: "var(--theme)",
              },
            }}
            variant="outlined"
          >
            Handefa
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}
