import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../App";
import { Dialog, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function DialogModal() {
  const { setDialog, setUser, setAlert } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const form = useRef(null);
  const handleLogin = async (e) => {
    e.preventDefault();
    const f = form.current;
    setLoading(true);
    try {
      const data = new FormData(f);
      const res = await fetch(process.env.REACT_APP_API + "/champion/login", {
        method: "POST",
        body: data,
      });
      const result = await res.json();
      if (result.success) {
        setUser(result.data.user);
        localStorage.setItem("tokenChampion", result.data.token);
        setAlert({ type: "success", message: "Tongasoa indray!" });
        setDialog(null);
      }else{
        throw new Error(result.message);
      }
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", message: "Misy diso ny mailaka sy teny miafina!" });
    }
    setLoading(false);
  };
  return (
    <Dialog open={true} onClose={() => setDialog(null)}>
      <div className="flex flex-col w-[300px] p-4 gap-4">
        <div className="flex justify-between">
          <h1 className="font-bold">Rohy fidirana</h1>
          <Close onClick={() => setDialog(null)} />
        </div>
        <form
          onSubmit={handleLogin}
          ref={form}
          className="flex flex-col gap-3 items-center"
        >
          <TextField name="email" type="email" required label="Adresy mailaka:" />
          <TextField name="password" type="password" required label="Teny miafina:" />
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{
              background: "var(--active)",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "Open sans",
              "&:hover": {
                background: "var(--theme)",
              },
            }}
            variant="outlined"
          >
            Connexion
          </LoadingButton>
        </form>
      </div>
    </Dialog>
  );
}
